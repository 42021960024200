import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'

export default function Main() {
   const router = useRouter()

   const [urlParams, setUrlParams] = useState(-1)

   useEffect(() => {
      // Check if site should be under maintenance
      if (process.env.NEXT_PUBLIC_UNDER_MAINTENANCE.toLowerCase() == 'true') {
         router.push('under-maintenance')
      } else {
         const queryString = window.location.search
         const urlParamstemp = new URLSearchParams(queryString)
         setUrlParams(urlParamstemp)
      }
   }, [])

   useEffect(() => {
      console.log('window.location.href', window.location.href)
      localStorage.clear()
      if (urlParams !== -1) {
         const userID = urlParams.get('userID')
         const qrcode = urlParams.get('qrcode')
         const partner = urlParams.get('partner')
         const testing = urlParams.get('test')
         const email = urlParams.get('email')
         console.log('userID HERE', userID)
         const partnerCID = urlParams.get('cID')
         const iscan = urlParams.get('iscan')

         if (userID && partner === null) {
            qrcode === 'true'
               ? localStorage.setItem('scan-quiz-show-intro', 'false')
               : localStorage.setItem('scan-quiz-show-intro', 'true')
            localStorage.setItem('userID', userID)
            router.push('smart-fit/smart-fit-quiz')
         } else if (partner) {
            console.log('userID in index.js', userID)
            if (userID) {
               localStorage.setItem('userID', userID)
            } //-EmrqY_N9vXpa_WZOwMR
            localStorage.setItem('partner', partner)
            localStorage.setItem('isPartner', 'TRUE')
            localStorage.setItem('email', email)
            localStorage.setItem('cID', partnerCID)
            if (partner === 'protect3d') {
               router.push('protect3d/protect3d-quiz')
            } else {
               router.push('partner-fit/partner-fit-quiz')
            }
         } else if (iscan === 'true') {
            router.push('iScan/i-smart-fit-scan-quiz')
         } else {
            router.push('no-user-id')
            //router.push('iScan/i-smart-fit-scan-quiz')
            // this needs to be used for testing? this also needs to point to a new page -> invalidlink
            // localStorage.setItem('userID', '-iXCAQQNwA7AYexryprN')
            // router.push('smart-fit-quiz/smart-fit-quiz')
            // localStorage.setItem('userID', '-pXhD3WNN3J18JNv9QHV')
            // router.push('partner-fit/partner-fit-scan')
            //router.push('testing')
            //router.push('/permissions/request-camera')

            //router.push({ pathname: '/something-went-wrong', query: { e: 'This is what i want to see in red' } })

            //outer.push('/landing-page')
            //router.push('smart-fit-quiz/smart-fit-scan-quiz')
            //router.push('/permissions/noGyroscope')
            //router.push('/payment')
            //router.push({ pathname: '/smart-fit-review' })
            //router.push('/smart-fit-quiz/finish-smart-fit-quiz')
            //router.push('completion-screen')
         }

         if (testing) {
            localStorage.setItem('Testing', testing)
         }
      }
   }, [urlParams])

   return null
}
